import React, { useEffect } from "react";
import { TextAnnotateBlend, AnnotateTag } from "react-text-annotate-blend";
import { useDispatch, useSelector } from 'react-redux';
// import { AMR_TAGS } from '../store/actions';
/*
Stateful example with blended tags not allowed
*/
import RadioButtonGroup from 'react-custom-radio-buttons-group';
import './textpenal.css';
// import TextWithBrackets from './BraceSpan';
// import TextAnnotatorWithBrackets from './TextAnnotatorWithBrackets';
// import CustomTextAnnotateBlend from '../text-annotate-blend-js/CustomTextAnnotateBlend';
import TextAnnotator from './AlignmentAnnotator/TextAnnotator';
import CustomRadioGroup from './CustomRadioGroup';

const color_book: string[] = [
  "#7498f0", "#63c6e0", "#c2fdfd", "#fecede", "#95d873", "#b9ee93", "#f6c430", "#f9ed19", "#ec68a2", "#fdb0da", "#f2f0ae", "#b4cbff", "#d9e5ff", "#c1f0f9", "#cdadd8", "#ebcef3", "#e392bd", "#5dc1b8", "#97d9cb", "#bcfbea", "#d9f5c4", "#f9c981", "#fefb95", "#d44c88", "#fde7ef", "#b67eb5", "#d173ce", "#977eb8", "#f7a78d", "#fbdcad"
];

// Function to assign colors
const getColorAssignment = (amrVars, span_data, color_book) => {
  // Create a map to keep track of color assignments
  const colorAssignment = {};
  
  // Create a set to keep track of used colors
  const usedColors = new Set();

  // Iterate through span_data to fill the color assignment map
  span_data.forEach(span => {
    if (span.color) {
      colorAssignment[span.tag] = span.color;
      usedColors.add(span.color);
    }
  });

  // Function to get the next available color from the color_book
  const getNextAvailableColor = () => {
    for (let color of color_book) {
      if (!usedColors.has(color)) {
        usedColors.add(color);
        return color;
      }
    }
    // If all colors are used, pick a random color from the color_book
    const randomColor = color_book[Math.floor(Math.random() * color_book.length)];
    usedColors.add(randomColor);
    return randomColor;
  };

  // Assign colors to variables in amrVars that don't have an assigned color
  amrVars.forEach(variable => {
    if (!colorAssignment[variable]) {
      colorAssignment[variable] = getNextAvailableColor();
    }
  });

  return colorAssignment;
};


function TextPanel({text, initValue, setValue, amrVars, amrVarsMap}) {
  const [tag, setTag] = React.useState("");
  const [tagColor, setTagColor] = React.useState("");

  const tagcolors = getColorAssignment(amrVars, initValue, color_book);

  let coloredValue = initValue.map(alignDict => ({...alignDict, color: tagcolors[alignDict.tag]}));
  
 

  const handleTagPicking = (selectedTag)=>{
    setTag(selectedTag);
    setTagColor(tagcolors[selectedTag]);
  };
  
  const tokens = text.split(/(\s+)/);

  const renderRadioButtonsWithTooltip = () => {
    return Object.keys(tagcolors).map((tag, index) => (
      <div key={index}>
        {tag}
        <div className="tooltip">
          {amrVarsMap[tag]}
        </div>
      </div>
    ));
  };
  
  return (
    <div style={{ padding: 20 }}>

      <div>
        <TextAnnotator tokens={tokens} alignments={initValue} setAlignments={setValue} selectedTag={tag} tagColor={tagColor} tagcolors={tagcolors}/>
      </div>

      <div className="radio-button-group">
        {/*<RadioButtonGroup
          onChange={handleTagPicking}
          hide={true}
          values={renderRadioButtonsWithTooltip()}
        />*/}
        <CustomRadioGroup 
          varColorsMap={tagcolors} 
          varConceptMap={amrVarsMap} 
          onChange={handleTagPicking}
        />
      </div>
      <p>&nbsp;</p>


      
    </div>
  );
}

export default TextPanel;