import axios from 'axios';
// import axiosInst from 'utils/axios';
import { store } from '../store';
import { REFRESH } from '../store/actions';

// const REACT_APP_BASE_BACKEND_URL = 'http://127.0.0.1:8000';
const REACT_APP_BASE_BACKEND_URL = 'https://camra.colorado.edu';

// import REACT_APP_BASE_BACKEND_URL from '../urls_const';

// export const BASE_API_URL = `${REACT_APP_BASE_BACKEND_URL}/api/v1`;
export const BASE_API_URL = `${REACT_APP_BASE_BACKEND_URL}/api`;

export async function AcctPassLogin(email, passwordStr) {
    const postdata = {
        username: email,
        password: passwordStr
    };
    console.log('before post: ', postdata);
    // const resp = post('auth/login/', { data });
    const body = JSON.stringify(postdata);
    const config = {
        headers: {
            'Content-Type': 'application/JSON'
        }
    };
    const URL = `${BASE_API_URL}/token/`;
    const res = await axios.post(URL, body, config);
    return res;
}


function getConfig(methodName, URL, accTK, paramsDict, contentType) {
    // eslint-disable-next-line
    let config = {
        method: methodName,
        url: URL,
        headers: {
            Authorization: `Bearer ${accTK}`,
            'Content-Type': contentType
        }
    };
    if (paramsDict !== null) {
        config.params = paramsDict;
    }
    return config;
}

function postConfig(methodName, URL, accTK, dataDict) {
    // eslint-disable-next-line
    let config = {
        method: methodName,
        url: URL,
        headers: {
            Authorization: `Bearer ${accTK}`,
            'Content-Type': 'application/JSON'
        }
    };
    if (dataDict !== null) {
        config.data = dataDict;
    }
    return config;
}

function getLocalAccessToken() {
    const account = store.getState().account;
    const accessToken = account.access;
    return accessToken;
}

function getLocalRefreshToken() {
    const account = store.getState().account;
    const refreshToken = account.refresh;
    return refreshToken;
}

async function refreshToken() {
    const URL = `${BASE_API_URL}/token/refresh/`;
    const refreshTK = getLocalRefreshToken();

    const postdata = { refresh: refreshTK };
    const data = JSON.stringify(postdata);
    const config = {
        baseURL: BASE_API_URL,
        headers: {
            'Content-Type': 'application/JSON'
        }
    };
    const rs = await axios.post(URL, data, config);
    const { access } = rs.data;

    store.dispatch({
        type: REFRESH,
        payload: { accessTK: access }
    });

    return access;
}

async function AxiosGETRequest(URL, params) {
    try {
        const accessToken = getLocalAccessToken();
        
        const config = getConfig('get', URL, accessToken, params, 'application/JSON');
        console.log('config: ', config);
        const { data } = await axios(config);
        return data;
    } catch (err) {
        console.log('catch getClients first error: ', err);
        if (err.response.status === 401) {
            try {
                const newAccTk = await refreshToken();

                const newconfig = getConfig('get', URL, newAccTk, params);

                const { data } = await axios(newconfig);

                return data;
            } catch (error) {
                console.log('more error... ', error);
                throw new Error('refresh token is also expired');
            }
        }
        return new Error('refresh token resolution failed, need to logout');
    }
}

// async function AxiosGETBlobRequest(URL, params) {
//     try {
//         const accessToken = getLocalAccessToken();
//         console.log('in getClients before await axiosInst.get, URL: ', URL);
//         const config = getPDFConfig('get', URL, accessToken, params);
//         console.log('config: ', config);
//         const { data } = await axios(config);
//         return data;
//     } catch (err) {
//         console.log('catch getClients first error: ', err);
//         if (err.response.status === 401) {
//             try {
//                 const newAccTk = await refreshToken();

//                 const newconfig = getConfig('get', URL, newAccTk, params);

//                 const { data } = await axios(newconfig);

//                 return data;
//             } catch (error) {
//                 console.log('more error... ', error);
//                 throw new Error('refresh token is also expired');
//             }
//         }
//         return new Error('refresh token resolution failed, need to logout');
//     }
// }

async function AxiosPOSTRequest(URL, dataDict, methodName) {
    try {
        const accessToken = getLocalAccessToken();
        
        const config = postConfig(methodName, URL, accessToken, dataDict);
        console.log('config: ', config);
        const { data } = await axios(config);
        return data;
    } catch (err) {
        console.log('catch getClients first error: ', err);
        if (err.response.status === 401) {
            try {
                const newAccTk = await refreshToken();
                const newconfig = postConfig(methodName, URL, newAccTk, dataDict);

                const { data } = await axios(newconfig);
                return data;
            } catch (error) {
                console.log('more error... ', error);
                return new Error('refresh token is also expired');
            }
        } else {
            throw err;
        }
    }
}

export async function getWorkSet() {
    const URL = `${BASE_API_URL}/exist_worksets/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, null);
    
    return resp;
}

export async function postWorkSet(dataDict) {
    const URL = `${BASE_API_URL}/upload_new_workset/`;

    try {
        const resp = await AxiosPOSTRequest(URL, dataDict, 'post');
        return resp;
    } catch (err) {
        console.error('Error in postWorkSet:', err);
        throw err;  // Or handle it in a different way depending on your needs
    }
}


export async function delWorkSet(dataDict) {
    const URL = `${BASE_API_URL}/del_workset/`;

    const resp = await AxiosPOSTRequest(URL, dataDict, 'put');
    
    return resp;
}

export async function getAMRDataFromWorkSet(worksetID, remove_dup_flag = false) {
    const URL = `${BASE_API_URL}/get_amrdata_by_workset/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {wsetID: worksetID});
    
    return resp;
}

export async function getDocAMRFromWorkSet(worksetID) {
    const URL = `${BASE_API_URL}/get_doc_amr_data/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {wsetID: worksetID});
    
    return resp;
}


export async function getDocDataFromWorkSet(worksetID) {
    const URL = `${BASE_API_URL}/msdoc_page/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {wsetID: worksetID});
    
    return resp;
}

export async function postAMRData(dataDict) {
    const URL = `${BASE_API_URL}/upload_AMRData/`;

    const resp = await AxiosPOSTRequest(URL, dataDict, 'put');
    
    return resp;
}

export async function getReleaseSearch(searchStr) {
    const URL = `${BASE_API_URL}/release_search/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {text: searchStr});
    
    return resp;
}

export async function getPropbankSearch(searchStr) {
    const URL = `${BASE_API_URL}/propbank_search/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {keyword: searchStr});
    
    return resp;
}

export async function getParserQuery(searchStr) {
    const URL = `${BASE_API_URL}/parser_query/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {text: searchStr});
    return resp;
}

export async function getAlignerQuery(dataDict) {
    const URL = `${BASE_API_URL}/aligner_query/`;
    // const paramsDict = {};
    const resp = await AxiosGETRequest(URL, {text: dataDict.text, amr: dataDict.amr});
    return resp;
}

export async function getAutoCompleteSnippet(dataDict) {
    const URL = `${BASE_API_URL}/snippet_search/`;
    const resp = await AxiosPOSTRequest(URL, dataDict, 'post');
    return resp;
}

export async function getProfileData() {
    const URL = `${BASE_API_URL}/profile/`;

    const resp = await AxiosGETRequest(URL, null);
    return resp;
}

export async function postProfileData(dataDict) {
    const URL = `${BASE_API_URL}/update_profile/`;

    const resp = await AxiosPOSTRequest(URL, dataDict, 'put');
    
    return resp;
}

export async function postPassWChange(dataDict) {
    const URL = `${BASE_API_URL}/password_change/`;

    const resp = await AxiosPOSTRequest(URL, dataDict, 'put');
    
    return resp;
}

export async function postAMRCorefs(dataDict) {
    const URL = `${BASE_API_URL}/amrcorefs_upload/`;

    const resp = await AxiosPOSTRequest(URL, dataDict, 'post');
    
    return resp;
}
