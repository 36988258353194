import React, { useState, useRef, useEffect } from 'react';
import { ExtraEditor, AMREditor } from './Editor';

// import useLocalStorage from '../hooks/useLocalStorage';

import WorksetManager from "./Uploader";
import ProfileManager from "./ProfileManager";

import SplitPane from "react-split-pane";

// import Col from 'react-bootstrap/Col';

import TextPanel from './TextPanel';
import './App.css';
import Pagination from '@mui/material/Pagination';
// import usePagination from "./Pagination";
import { default as annotationData } from "./MOCK_DATA.json";


import PropbankRoleListPop from './PropbankRoleListPop';
import ReleaseListNWPop from './ReleaseListNWPop';
import AMRGuidePop from './AMRGuidePop';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

// for New menu compoenents
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@mui/icons-material/Info';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Search, SearchIconWrapper, StyledInputBase } from './menustyle';
// end of New menu components
import { LOGOUT } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postAMRData, getReleaseSearch, getPropbankSearch, getParserQuery, getAlignerQuery, getAMRDataFromWorkSet, getProfileData } from '../services/auth';
import { checkNewVar } from '../utils/amrUtils';
// const BASE_API_URL = 'http://127.0.0.1:5000';


const role_set_init = [{ "roleset_id": "examples", "examples": [{ "example_name": "1st example", "example_text": "first example text", "args": [{ "arg_text": "example", "f": "", "n": "0" }], "rel": { "rel_text": "st" } }] }];
const release_amr_init = [{ "snt": "this is a test sent", "amr": "(t / this\n  :equal (s / snt\n    :mod (t / test)\n  )\n)" }];

function App() {
  // const [text, setText] = useLocalStorage('text', '');
  // const [css, setCss] = useLocalStorage('css', '');
  // const [js, setJs] = useLocalStorage('js', '');

  const dispatch = useDispatch();
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);


  const [heightValue, setHeightValue] = useState("485px");
  const [keyword, setKeyword] = useState('');
  const [rskeyword, setRSKeyword] = useState('');
  // const [searchword, setSearchWord] = useState('');

  const [rolesetsFilter, setRolesetsFilter] = useState(role_set_init);
  const [releaseFilter, setReleaseFilter] = useState(release_amr_init);
  const [uploadShow, setUploadShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [propbankQueryReady, setPropbankQueryReady] = useState(false);
  const [releaseQueryReady, setReleaseQueryReady] = useState(false);
  const [anchorElDrawer, setAnchorElDrawer] = useState(null);

  // const filestrings = JSON.stringify({currentFileName: annotationData});  
  // const [allAnnotationData, setAllAnnotationData] = useLocalStorage('AMRAnnotationData', filestrings);

  const [page, setPage] = useState(1);

  // const [perPageSents, setPerPageSents] = useState(1);

  
  const [totalPages, setTotalPages] = useState(annotationData.length);
  const allAnnotationRef = useRef(null);

  const [currentPageText, setCurrentPageText] = useState('');
  const [currentPageAMRs, setCurrentPageAMRs] = useState('');
  const [currentPageTags, setCurrentPageTags] = useState([]);
  const [currentVars, setCurrentVars] = useState([]);


  const [currentVarsMap, setCurrentVarsMap] = useState({});
  const [currentPageParserAMRs, setCurrentPageParserAMRs] = useState('');

  const [showHome, setShowHome] = useState(true);
  const [showDocView, setShowDocView] = useState(false);

  const handleDrawerClick = (event) => {
    setAnchorElDrawer(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setAnchorElDrawer(null);
  };

  const handleOnChange = (event) => {
    console.log("handleOnChange: event.target.value: ", event.target.value);
    setKeyword(event.target.value);
  };

  const RShandleOnChange = (event) => {
    setRSKeyword(event.target.value);
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    // setSearchWord(keyword);
    propbankQuery(keyword);
  };
  const RShandleOnSubmit = (event) => {
    event.preventDefault();
    // setSearchWord(keyword);
    releaseQuery(rskeyword);
  };

  const handleChangeCurrentWorkSet = (data, lastIndex) => {
    // do something to update the text

    allAnnotationRef.current = data;
    setCurrentPageText(allAnnotationRef.current[lastIndex]['text']);
    setCurrentPageAMRs(allAnnotationRef.current[lastIndex]['AMR']);
    setCurrentPageTags(allAnnotationRef.current[lastIndex]['AMR_aligns']);
    setCurrentVars(allAnnotationRef.current[lastIndex]['AMR_vars']);


    if (allAnnotationRef.current[lastIndex]['parser_result'] === undefined || allAnnotationRef.current[lastIndex]['parser_result'] === "") {
      setCurrentPageParserAMRs('no results yet _(:3」∠ )_');

      parserQuery(allAnnotationRef.current[lastIndex]['text'], lastIndex);

    } else {
      setCurrentPageParserAMRs(allAnnotationRef.current[lastIndex]['parser_result']);
    }

    const new_flag_tuple = checkNewVar(allAnnotationRef.current[lastIndex]['AMR'], []);
    
    setCurrentVarsMap(new_flag_tuple[2]);
    
    // setCurrentPageParserAMRs(allAnnotationRef.current[lastIndex]['parser_result']);
    setTotalPages(data.length);
    setPage(lastIndex + 1);

  };
  // load data from backend ---------------

  useEffect(() => {
    const fetchClusterData = async () => {
      setLoading(true);
      try {
        const userProfile = await getProfileData();
        const lastWorksetID = userProfile['last_workset_id']
        // prepare text:
        
        const response = await getAMRDataFromWorkSet(lastWorksetID);

        const amrData = response.amrs;
        const lastIndex = response.last_index;
        handleChangeCurrentWorkSet(amrData, lastIndex);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClusterData();
  }, []);

  // --------------

  // console.log("cur_text: ", cur_text);
  const propbankQueryUnload = () => {
    setPropbankQueryReady(false);
  };

  const releaseQueryUnload = () => {
    setReleaseQueryReady(false);
  }

  // config the annotation document pop up unload func
  const [AMRGuideReady, setAMRGuideReady] = useState(false);
  const AMRGuideUnload = () => {
    setAMRGuideReady(false);
  };
  const handleAMRGuideInvoke = () => {
    setAMRGuideReady(true);
  }

  async function alignerQuery(cur_text, amr_str, data_index) {

    try {
      const response = await getAlignerQuery({text: cur_text, amr: amr_str});
      console.log("response: ", response);
      const suggestedAlignment = response.map((alignDict) => ({...alignDict, 'tag': alignDict.var}));
      setCurrentPageTags(suggestedAlignment);
      allAnnotationRef.current[data_index]['AMR_aligns'] = suggestedAlignment;
      // setCurrentPageParserAMRs(amr_string.amr);
      // allAnnotationRef.current[data_index]['parser_result'] = amr_string.amr;

    } catch (error) {
      console.log('parser error: ', error);
    }
  }

  const handlePageChange = async (e, newp) => {

    const newPageAnnot = {
      'id': allAnnotationRef.current[page - 1].id,
      'text': currentPageText,
      'AMR': currentPageAMRs,
      'AMR_aligns': currentPageTags,
      'AMR_vars': currentVars,
      'parser_result': allAnnotationRef.current[page - 1]['parser_result'],
    };
    // const currentFileAllAData = allAnnotationRef.current.map((dict, index) => index === (currentPage-1) ? newPageAnnot : dict );
    allAnnotationRef.current[page - 1] = newPageAnnot;

    // setAllAnnotationData('allAnnotationData', JSON.stringify({currentFileName: currentFileAllAData}));

    // update individual AMRData

    const resp = await postAMRData({ lastIndex: page-1, AMRData: newPageAnnot }).catch((e) => { console.log(e); });
    if (resp !== undefined) {
      setPage(newp);
      // before changing save current progress

      setCurrentPageText(allAnnotationRef.current[newp - 1]['text']);
      setCurrentPageAMRs(allAnnotationRef.current[newp - 1]['AMR']);
      if (allAnnotationRef.current[newp - 1]['AMR'] === undefined || allAnnotationRef.current[newp - 1]['AMR'] === ''){
        setCurrentVars([]);
        setCurrentVarsMap({});
      } else {
        const new_flag_tuple = checkNewVar(allAnnotationRef.current[newp - 1]['AMR'], []);

        setCurrentVars(new_flag_tuple[1]);
        setCurrentVarsMap(new_flag_tuple[2]);
      }
      if (allAnnotationRef.current[newp - 1]['AMR_aligns'] === undefined || allAnnotationRef.current[newp - 1]['AMR_aligns'].length === 0){
        alignerQuery(allAnnotationRef.current[newp - 1]['text'], allAnnotationRef.current[newp - 1]['AMR'], newp - 1);
      } else {
        setCurrentPageTags(allAnnotationRef.current[newp - 1]['AMR_aligns']);
      }
      setCurrentPageTags(allAnnotationRef.current[newp - 1]['AMR_aligns']);
      
      // ==============REACTIVATE parser server line==============
      if (allAnnotationRef.current[newp - 1]['parser_result'] === undefined || allAnnotationRef.current[newp - 1]['parser_result'] === "") {
        setCurrentPageParserAMRs('no results yet _(:3」∠ )_');
        console.log('allAnnotationRef.current[newp-1]: ', allAnnotationRef.current[newp - 1])
        parserQuery(allAnnotationRef.current[newp - 1]['text'], newp - 1);

      } else {
        setCurrentPageParserAMRs(allAnnotationRef.current[newp - 1]['parser_result']);
      }


    } else {
      alert('failed to push to DB');
    }
  };

  async function parserQuery(cur_text, data_index) {

    try {
      const response = await getParserQuery(cur_text);
      console.log("response: ", response);
      const amr_string = JSON.parse(response);
      setCurrentPageParserAMRs(amr_string.amr);
      allAnnotationRef.current[data_index]['parser_result'] = amr_string.amr;

    } catch (error) {
      console.log('parser error: ', error);
    }
  }
  async function propbankQuery(keyword) {


    try {
      setLoading(true);
      const response = await getPropbankSearch(keyword);
      console.log("response: ", response);

      setRolesetsFilter(response);
      setPropbankQueryReady(true);
      console.log("in propbank Query")
      setLoading(false);

    } catch (error) {
      console.log("error: ", error);
      setRolesetsFilter(role_set_init);
      setLoading(false);
    }
  }
  async function releaseQuery(keyword) {

    try {
      setLoading(true);
      const response = await getReleaseSearch(keyword);
      console.log("release query response: ", response);

      setReleaseFilter(response);
      setReleaseQueryReady(true);
      setLoading(false);

    } catch (error) {
      console.log("error: ", error);
      setReleaseFilter(release_amr_init);
      setLoading(false);
    }
  }

  const handleLogout = () => {
    setAnchorElDrawer(null);
    dispatch({ type: LOGOUT });
    navigate('/login', { replace: true });
  };

  const handleHomeClick = () => {
    handleDrawerClose(); // Assuming you want to close the drawer when Home is clicked
  };

  const handleDocView = () => {
    navigate('/docview');
    handleDrawerClose(); // Assuming you want to close the drawer when Home is clicked
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="inherit" sx={{ height: 60 }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              aria-controls={Boolean(anchorElDrawer) ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElDrawer) ? 'true' : undefined}
              onClick={handleDrawerClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorElDrawer}
              open={Boolean(anchorElDrawer)}
              onClose={handleDrawerClose}
              TransitionComponent={Fade}
            >
                            <MenuItem onClick={handleHomeClick}>Home</MenuItem>

              <MenuItem onClick={() => {
                setUploadShow(true);
                handleDrawerClose();
              }}>
                Work Sets
              </MenuItem>
              <MenuItem onClick={() => {
                setProfileShow(true);
                handleDrawerClose();
              }}>
                Profile</MenuItem>
              <MenuItem onClick={handleDocView}>
                Document View
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              CAMRA
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Pagination
                  count={totalPages}
                  color="primary"
                  variant="outlined"
                  page={page}
                  siblingCount={1}
                  onChange={handlePageChange}
                />
              </Box>
            </Box>
            <form onSubmit={handleOnSubmit}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Propbank…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleOnChange}
                />
              </Search>
            </form>
            <form onSubmit={RShandleOnSubmit}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Release…"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => RShandleOnChange(e)}
                />
              </Search>
            </form>
            <IconButton 
              aria-label="guide"
              size="medium"
              onClick={handleAMRGuideInvoke}
            >
              <InfoIcon fontSize="inherit" />
            </IconButton>
            {AMRGuideReady && (<AMRGuidePop unloadFunc={AMRGuideUnload} />
            )}
            {propbankQueryReady && (<PropbankRoleListPop roleList={rolesetsFilter} unloadFunc={propbankQueryUnload} />
            )}
            {releaseQueryReady && (<ReleaseListNWPop sentAMRs={releaseFilter} unloadFunc={releaseQueryUnload} />
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <WorksetManager
        show={uploadShow}
        onHide={() => setUploadShow(false)}
        changeCurrentWorkset={handleChangeCurrentWorkSet}
      />
      <ProfileManager
        show={profileShow}
        onHide={() => setProfileShow(false)}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleLoadingClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SplitPane
        style={{ marginTop: "60px" }}
        split="horizontal"
        minSize={"100%"}
        onDragFinished={(height) => {
          setHeightValue(`${height - 40}px`);
        }}
      >
        <SplitPane split="vertical" minSize={"33%"}>
          <TextPanel text={currentPageText} initValue={currentPageTags} setValue={setCurrentPageTags} amrVars={currentVars} amrVarsMap={currentVarsMap}/>
          <SplitPane split="vertical" minSize={"50%"}>
            <AMREditor
              height={heightValue}
              value={currentPageAMRs}
              setValue={setCurrentPageAMRs}
              amrVars={currentVars}
              setAMRVars={setCurrentVars}
              setAMRVarsMap={setCurrentVarsMap}
            />
            <ExtraEditor
              height={heightValue}
              value={currentPageParserAMRs}
              setValue={setCurrentPageParserAMRs}
            />
          </SplitPane>
        </SplitPane>
      </SplitPane>

    </>
  )
}

export default App;
